import { UseContentSlider } from "../../Components/useContentSlider";
import "./content-slider.css";
import contentSliderImg from "/images/text-shape.png";

const ContentSlider = () => {
  const ContentSlider = UseContentSlider();
  return (
    <div className="content-slider-section">
      <div className="content-slider">
        {ContentSlider &&
          ContentSlider.map((slide, i) => (
            <>
              <div key={i} className="content-slider-content">
              <h1>{slide.ContentsliderText}</h1>
              </div>
              <div className="content-slider-content">
                <img src={contentSliderImg} />
              </div>
            </>
          ))}
      </div>
      <div className="content-slider">
        {ContentSlider &&
          ContentSlider.map((slide, i) => (
            <>
              <div key={i} className="content-slider-content">
              <h1>{slide.ContentsliderText}</h1>
              </div>
              <div className="content-slider-content">
                <img src={contentSliderImg} />
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default ContentSlider;
