import { useEffect, useState } from "react";  
import { GetAboutSection } from "../data/AboutController";

export const UseAbout = () => { 
  const [HomAbout, setHomAbout] = useState([]);
  useEffect(() => {
    const func = async () => {
      try {
        const GetData = await GetAboutSection();
        setHomAbout(GetData);
    } catch (error) {
        console.log(error);
      }
    };

    func();
  }, []);

  return HomAbout;
};
