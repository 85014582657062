export const GetServicesInfo= async () => { 
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify({ action: "GetServicesInfo" }),
      // credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status} - ${response.statusText}`
      );
    }
    const data = await response.json(); 
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(`Error message: ${error.message}`);
  }
};
export const GetServicesCards= async () => { 
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify({ action: "GetServicesCards" }),
      // credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status} - ${response.statusText}`
      );
    }
    const data = await response.json(); 
    console.log(data)
    return data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(`Error message: ${error.message}`);
  }
};
