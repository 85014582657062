import { Link, useLocation } from "react-router-dom";
import BreadCrumb from "../../../Shared/BreadCrumb/BreadCrumb";
import wedgetIcon from "/images/widget-icon.png";
import projectDetialsBoxImg from "/images/services-details-benifis-thumb-1.png";
import projectDetialsBoxImg2 from "/images/services-details-benifis-thumb-2.png";
import {
  FaArrowRight,
  FaArrowRightLong,
  FaRegFolderOpen,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { UseServicesCards } from "../../../Components/useServices";
import { uploadUrl } from "../../../Components/urls";
import { useEffect, useState } from "react";

const ServiceDetails = () => {
  const serviceCards = UseServicesCards();

  const { state } = useLocation() || {};
  // if (!state) return null;

  const card = state ? state : serviceCards ? serviceCards[0] : [];

  const subService = card?.SubService || [];

  const [SubSelected, setSubSelected] = useState([
    ...subService.filter((sb) => sb.SubNewPrerequisite === 1),
  ]);

  // Example of usage in a function or effect
  useEffect(() => {
    if (subService.length > 0) {
      setSubSelected([
        ...subService.filter((sb) => sb.SubNewPrerequisite === 1),
      ]);
    }
  }, [subService]);

  if (!card) return null;
  console.log(card);
  const TotalPrice = () => {
    const total = SubSelected
      ? SubSelected.reduce((acc, srv) => acc + (srv.SubIsNewPrice || 0), 0)
      : 0;
    return total + Number(card.ServicePrice);
  };

  const HandelSelectSub = (sub) => {
    const exists = SubSelected.some(
      (item) => item.SubServiceID === sub.SubServiceID
    ); // assuming each item has a unique 'SubServiceID '

    const updatedSubSelected = exists
      ? SubSelected.filter((item) => item.SubServiceID !== sub.SubServiceID) // remove if it exists
      : [...SubSelected, sub]; // add if it doesn't exist

    setSubSelected(updatedSubSelected);
  };

  console.log(state);

  return (
    <>
      <BreadCrumb
        breadCrumbPhoto={uploadUrl + "/" + card.ServiceCardPhoto}
        breadCrumbTitle={card.ServiceCardTitle}
        breadCrumbIcon={<FaArrowRightLong />}
        breadCrumbLink={"Serivces"}
        breadCrumbIcon2={<FaArrowRightLong />}
        breadCrumbContent={card.ServiceCardTitle}
      />
      <section className="py-[120px] bg-white">
        <div className="Container">
          <div className="grid grid-cols-3 gap-[70px]">
            <div className="col-span-3 lg:col-span-2">
              <img
                src={
                  // serviceDetailsThumb
                  uploadUrl + "/" + card.ServiceCardPhoto
                }
                draggable="false"
                className="w-full"
              />
              <h2 className="font-AlbertSans font-bold text-[24px] sm:text-4xl text-HeadingColor-0 mt-8">
                {card.ServiceCardTitle} {card.ServicePrice }
              </h2>
              <p className="font-AlbertSans text-TextColor-0 mt-6">
                {card.ServiceCardText}
              </p>
              <p className="font-AlbertSans text-TextColor-0 mt-7 mb-11"></p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
                {card.SubService &&
                  card.SubService.map((sub, i) => (
                    <div
                      key={i}
                      className={
                        SubSelected.some(
                          (item) => item.SubServiceID === sub.SubServiceID
                        )
                          ? "flex flex-col md:flex-row lg:flex-col xl:flex-row gap-5 bg-[#f3f4f8] rounded-md px-6 py-7"
                          : "flex flex-col md:flex-row lg:flex-col xl:flex-row gap-5 bg-[#f3f4f8] rounded-md px-6 py-7"
                      }
                      style={{
                        pointerEvents: sub.SubNewPrerequisite ? "none" : "auto",
                        opacity:
                          SubSelected.some(
                            (item) => item.SubServiceID === sub.SubServiceID
                          ) || sub.SubNewPrerequisite
                            ? 1
                            : 0.5,
                        cursor: sub.SubNewPrerequisite ? "" : "pointer",
                      }}
                      onClick={() => HandelSelectSub(sub)}
                    >
                      <div className="w-[72px] h-[72px] rounded-full bg-white flex justify-center items-center">
                        <img
                          src={uploadUrl + "/" + sub.SubServicePhoto}
                          draggable="false"
                        />
                      </div>
                      <div className="flex-1">
                        <h6 className="font-AlbertSans font-semibold text-[22px] text-HeadingColor-0">
                          {sub.SubServiceTitle} ${sub.SubIsNewPrice}
                        </h6>
                        <p className="font-AlbertSans text-TextColor-0 mt-3">
                          {sub.SubServiceDesc}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <div className="bg-PrimaryColor-0 px-[142px] rounded-md py-7 flex flex-col sm:flex-row sm:items-center gap-8 mt-[62px]"> */}
              {/* <div className="flex-1">
                <div 
                        style={{
                          color: "#fff",
                          fontSize: "34px",
                          display: "flex", 
                          alignItems: "center", 
                          gap: "10px", 
                          margin:'2%'
                        }}
                      >
                        
                        <div className="col w-[72px] h-[72px] rounded-full bg-white flex justify-center items-center">
                        <FaRegFolderOpen
                                  className=  "text-PrimaryColor-0 transition-all duration-500 group-hover:text-white" 
                                />
                        </div>
                        <p className="font-AlbertSans italic text-sm sm:text-[22px] leading-10 text-white text-center">
                         
                        {card.ServiceCardTitle}   ${card.ServicePrice}  
                        </p>
                      </div>
                  {SubSelected &&
                    SubSelected.map((sub, i) => (
                      <div
                        key={i}
                        style={{
                          color: "#fff",
                          fontSize: "34px",
                          display: "flex", 
                          alignItems: "center", 
                          gap: "10px", 
                          margin:'2%'
                        }}
                      >
                        <div className="col w-[72px] h-[72px] rounded-full bg-white flex justify-center items-center">
                          <img
                            src={uploadUrl + sub.SubServicePhoto}
                            draggable="false"
                          />
                        </div>
                        <p className="font-AlbertSans italic text-sm sm:text-[22px] leading-10 text-white text-center">
                          {sub.SubServiceTitle} ${sub.SubIsNewPrice}{" "}
                        </p>
                      </div>
                    ))}
                </div> */}
              <div
                className=" pr-8  border-[#94c933] p-2 text-left"
                style={{ color: "#fff", fontSize: "24px" }}
              >
                <Link to={"/"}>
                  <button className="font-AlbertSans text-white flex gap-2 items-center bg-[#94c933] p-6 w-full h-[28px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white">
                    <div
                      className="  border-[#ccc] p-3 text-center"
                      style={{
                        color: "#fff",
                        fontSize: "24px",
                        fontWeight: "bolder",
                      }}
                    >
                      ${TotalPrice()}
                    </div>
                    Checkout
                    <FaArrowRight />
                  </button>
                </Link>
              </div>

              {/* </div> */}
              {/* <div className="bg-PrimaryColor-0 px-[42px] rounded-md py-7 flex flex-col sm:flex-row sm:items-center gap-8 mt-[62px]">
                <div className=" pr-8 sm:border-r-2 border-[#94c933]">
                  <img src={icon} draggable="false" />
                </div>
                <div className="flex-1">
                  <p className="font-AlbertSans italic text-lg sm:text-[22px] leading-8 text-white">
                    Competently architect intermandated deliverables client
                    <br className="hidden 2xl:block" />
                    niches continually underwhelm
                  </p>
                </div>
              </div> */}
              <h2 className="font-AlbertSans font-bold text-2xl sm:text-3xl text-HeadingColor-0 mt-[54px]">
                What the Benifits?
              </h2>
              <p className="font-AlbertSans text-TextColor-0 mt-6">
                Alternative innovation to ethical network environmental
                whiteboard pursue compelling results for premier methods
                empowerment. Dramatically architect go forward opportunities
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mt-9">
                <div className="text-center group">
                  <div className="overflow-hidden rounded">
                    <img
                      src={projectDetialsBoxImg}
                      draggable="false"
                      className="transition-all duration-500 group-hover:scale-110"
                    />
                  </div>
                  <div className="bg-white shadow-cases rounded inline-block pt-7 pl-4 sm:pl-8 pb-7 w-4/5 -mt-5 relative z-10">
                    <h4 className="font-AlbertSans text-HeadingColor-0 font-semibold text-[22px] lg:text-xl xl:text-[22px] text-left mb-3">
                      Green Environment
                    </h4>
                    <ul>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans mb-2 lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        New Modern Equipments
                      </li>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        Expert’s Volunteers
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center group">
                  <div className="overflow-hidden rounded">
                    <img
                      src={projectDetialsBoxImg2}
                      draggable="false"
                      className="transition-all duration-500 group-hover:scale-110"
                    />
                  </div>
                  <div className="bg-white shadow-cases rounded inline-block pt-7 pl-4 sm:pl-8 pb-7 w-4/5 -mt-5 relative z-10">
                    <h4 className="font-AlbertSans text-HeadingColor-0 font-semibold text-[22px] lg:text-xl xl:text-[22px] text-left mb-3">
                      Renewable Energy
                    </h4>
                    <ul>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans mb-2 lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        New Modern Equipments
                      </li>
                      <li className="flex items-center gap-2 text-TextColor-0 text-left font-AlbertSans lg:text-sm xl:text-base">
                        <IoMdCheckmark className="text-PrimaryColor-0 text-xl" />
                        Expert’s Volunteers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 lg:col-span-1">
              <div className="bg-[#f3f4f8] rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
                <h4 className="font-AlbertSans font-semibold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-7 before:h-[2px] before:bg-PrimaryColor-0">
                  Services
                </h4>
                <ul className="mt-8">
                  {serviceCards &&
                    serviceCards.map((service, i) => (
                      <>
                        <li key={i}>
                          <Link state={service}>
                            <button
                              className={
                                service.ServicesID === card.ServicesID
                                  ? "w-full font-AlbertSans text-left text-white transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-PrimaryColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white"
                                  : "w-full font-AlbertSans text-left text-HeadingColor-0 transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-white mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white"
                              }
                            >
                              <span className="flex items-center gap-3">
                                <FaRegFolderOpen
                                  className={
                                    service.ServicesID === card.ServicesID
                                      ? "text-white transition-all duration-500 group-hover:text-white"
                                      : "text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                                  }
                                />
                                {service.ServiceCardTitle}
                              </span>
                              <FaArrowRightLong className="text-white" />
                            </button>
                          </Link>
                        </li>
                      </>
                    ))}
                </ul>
              </div>
              <div className="bg-[#f3f4f8] rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
                <h4 className="font-AlbertSans font-semibold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-7 before:h-[2px] before:bg-PrimaryColor-0">
                  Downloads
                </h4>
                <ul className="mt-8">
                  <li>
                    <Link to={"/"}>
                      <button className="w-full font-AlbertSans bg-SecondaryColor-0 text-left text-white transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white">
                        <span className="flex items-center gap-3">
                          <BsFileEarmarkPdf
                            size={"20"}
                            className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                          />
                          Service Report
                        </span>
                        <HiDownload size={"24"} className="text-white" />
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <button className="w-full font-AlbertSans bg-SecondaryColor-0 text-left text-white transition-all duration-500 group px-7 py-4 flex items-center justify-between rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white">
                        <span className="flex items-center gap-3">
                          <BsFileEarmarkPdf
                            size={"20"}
                            className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white"
                          />
                          Service List
                        </span>
                        <HiDownload size={"24"} className="text-white" />
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="rounded-lg px-9 overflow-hidden bg-[url('/images/sidebar-bg.jpg')] bg-cover bg-no-repeat bg-center py-[50px]">
                <img src={wedgetIcon} draggable="false" />
                <h6 className="font-AlbertSans font-medium text-lg text-white mt-5 mb-2">
                  Call Us Anytime
                </h6>
                <Link to={"/"}>
                  <button className="font-AlbertSans font-semibold text-2xl text-white">
                    +123 (4567) 890
                  </button>
                </Link>
                <Link to={"/"}>
                  <button className="font-AlbertSans text-white flex gap-2 items-center mt-4 mb-[52px]">
                    <MdEmail className="text-xl text-PrimaryColor-0" />
                    example@gmail.com
                  </button>
                </Link>
                <Link to={"/"}>
                  <button className="font-AlbertSans text-white flex gap-2 items-center bg-PrimaryColor-0 w-full h-[58px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white">
                    Contact Us
                    <FaArrowRight />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetails;
