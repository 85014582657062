import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./Router/Router.jsx";
import { RouterProvider } from "react-router-dom";
// import { HelmetProvider } from "react-helmet-async";
// import HelmetChanger from "./Shared/Helmet/Helmet.jsx";

// const expirationDate = new Date("2024-09-22"); 
// const currentDate = new Date();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <React.StrictMode>
      {/* <HelmetProvider context={HelmetChanger}> */}
      <RouterProvider router={router} />
      {/* </HelmetProvider> */}
    </React.StrictMode>
  </React.StrictMode>
);

// if (currentDate > expirationDate) {
//   ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       <div style={{ textAlign: "center", padding: "50px", fontSize: "24px" }}>
//         The application has expired. Please contact support.
//       </div>
//     </React.StrictMode>
//   );
// } else {
//   ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       {/* <HelmetProvider context={HelmetChanger}> */}
//       <RouterProvider router={router} />
//       {/* </HelmetProvider> */}
//     </React.StrictMode>
//   );
// }